import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Tile({ label, to }) {
  const navigate = useNavigate();

  return (
    <div className="tile" onClick={() => navigate(to)}>
      <label>
        {label} <BsChevronRight />
      </label>
    </div>
  );
}

export default Tile;
