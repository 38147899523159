function Facts1() {
  return (
    <div>
      <h1>Frohe Weihnachten</h1>
      <div className="two-columns">
        <div>
          <h4>Wieso muss man sich unter dem Mistelzweig küssen?</h4>
          <p>Happy End einer nordischen Göttersage: Da heisst es, dass der Mistelzweig die heilige Pflanze der Liebesgöttin Frigg war. Doch ihr Sohn Balder wurde ausgerechnet mit einem Pfeil aus diesem Zweig getötet. Nur mit grösster Mühe konnte Frigg ihn ins Leben zurückholen. Aus Freude darüber küsste sie jeden, der unter dem Baum entlang ging, von dem der verhängnisvolle Ast stammte. </p>
        </div>
        <div>
          <h4>Waren Weihnachtsbäume schon immer Tannen?</h4>
          <p>Heute gehört eine schön geschmückte Nordmanntanne -oder ein ähnlicher Nadelbaum zum Fest der Liebe dazu. Dabei war der erste deutsche Weihnachtsbaum viel exotischer. 1570 stand im Bremer Zunfthaus der Handwerker eine Dattelpalme. Diese wurde mit kleinen Köstlichkeiten geschmückt und nach den Feiertagen gemeinsam geplündert.</p>
        </div>
      </div>
    </div>
  );
}

export default Facts1;
