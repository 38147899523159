function Facts4() {
  return (
    <div>
      <h1>Frohe Weihnachten</h1>
      <div className="two-columns">
        <div>
          <h4>Was wäre ein Tannenbaum ohne bunte Geschenke?</h4>
          <p>Keine Sorge, die Tradition der Weihnachtsgeschenke ist keine Erfindung des Einzelhandels. Sie reicht sogar bis zu den alten Römern zurück, die sich durch gegenseitige Gaben Glück für das neue Jahr erhofften. Die Bescherung unter dem Tannenbaum, wie wir sie heute kennen, ist allerdings erst im 18.Jahrhundert entstanden. Früher sollten die Gaben auch eher einen pädagogischen Aspekt erfüllen, um die Kinder für ihr gutes Verhalten zu belohnen.</p>
        </div>
        <div>
          <h4>Wann wurde die Kerze erfunden?</h4>
          <p>Bereits vor 3 000 Jahren tauchten die Bewohner des Orients Stroh in Fett, um eine ausdauernde Lichtquelle zu erzeugen. Das stank jedoch fürchterlich. Da waren die niedrigen Talglichter, die die Römer seit dem 2. Jahrhundert n.Chr.verwendeten, schon besser. Aber erst als der Franzose Jules de Cambacérès um 1828 den geflochtenen Docht entwickelte, fand die Kerze zu ihrer heutigen Form.</p>
        </div>
      </div>
    </div>
  );
}

export default Facts4;
