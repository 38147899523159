import { Link, useNavigate } from "react-router-dom";
import "./Header.css";

function Header() {
  const navigate = useNavigate();
  return (
    <header>
      <div className="header-wrapper">
        <div className="desktop-spacer"></div>
        <h1 onClick={() => navigate("/")}>Aegi's Weihnachtswelt</h1>
        <nav>
          <ul>
            <li>
              <Link to="/">Übersicht</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
