import { useCallback, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./App.css";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./pages/Home/Home";
import particleConfig from "./res/particleConfig.json";
import Facts1 from "./pages/facts1";
import Stories1 from "./pages/stories1";
import Stories2 from "./pages/stories2";
import Songs1 from "./pages/songs1";
import SongFacts1 from "./pages/songFacts1";
import SongFacts2 from "./pages/songFacts2";
import Facts2 from "./pages/facts2";
import Facts3 from "./pages/facts3";
import Facts4 from "./pages/facts4";
import Facts5 from "./pages/facts5";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => { }, []);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-VSSZV0FQLG",
        gaOptions: {
          siteSpeedSampleRate: 100,
          alwaysSendReferrer: true
        }
      }
    ]);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleConfig}
      />
      <Header />
      <div className="content">
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/facts/1" element={<Facts1 />} />
            <Route path="/stories/1" element={<Stories1 />} />
            <Route path="/stories/2" element={<Stories2 />} />
            <Route path="/songs/1" element={<Songs1 />} />
            <Route path="/facts/songs/1" element={<SongFacts1 />} />
            <Route path="/facts/songs/2" element={<SongFacts2 />} />
            <Route path="/facts/2" element={<Facts2 />} />
            <Route path="/facts/3" element={<Facts3 />} />
            <Route path="/facts/4" element={<Facts4 />} />
            <Route path="/facts/5" element={<Facts5 />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
