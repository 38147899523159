function SongFacts2() {
  return (
    <div>
      <h1>Fakten zu Weihnachtsliedern - Teil 2</h1>
      <div className="one-column">
        <h4>Das Längste: "Heiligobndlied" - Johanne Amalie von Elterlein</h4>
        <p>Das Heiligobndlied ist das wohl längste Weihnachtslied der Welt. Um das Jahr 1799 soll die damals 15-jährige Johanne Amalie von Elterlein aus Annaberg die 13-strophige Urfassung geschrieben haben. Der Text spiegelt verschiedene Bräuche zur Weihnachtszeit - wie z.B. das Bleigiessen - und das damalige Familienleben der Arbeiterschicht wieder. Mit der Zeit gesellten sich immer wieder neue Strophen hinzu.<br />Im Laufe der Jahre ist das Lied allerdings auf 156 Strophen angeschwollen und die Aufführungsdauerbeträgt inzwischen über eine Stunde.</p>
        <h4>Das Kürzeste: "The World's Shortest Christmas Song" - Acappella Yuletide Uproar</h4>
        <p>Der Songschafft es auf eine Spielzeit von 6 Sekunden - wobei der eigentliche musikalische Teil nur 2 Sekunden dauert.</p>
        <h4>Das Einfachste: "Feliz Navidad" - José Feliciano</h4>
        <p>Nach eigenen Angaben komponierte José Felicianodas Liedin fünf Minuten. Der Song hat drei Akkorde und einen denkbar einfachen Text: 21 mal „Feliz Navidad“ und 16 mal „I wanna wish you a MerryChristmas“ plus zwei weitereZeilen.</p>
      </div>
    </div>
  );
}

export default SongFacts2;
