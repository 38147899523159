function Stories1() {
  return (
    <div>
      <h1>Die Weihnachtsgeschichte</h1>
      <div className="one-column">
        <h4>Was ist die Weihnachtsgeschichte und woher stammt sie?</h4>
        <p>Bei der klassischen Weihnachtsgeschichte handelt es sich um eine Erzählung aus der Bibel, die von der Geburt Jesu von Nazareth erzählt.</p>
        <br />
        <h4>Wer hat die Weihnachtsgeschichte geschrieben, wo steht sie?</h4>
        <p>Die biblische Weihnachtsgeschichte wird in zwei unterschiedlichen Versionen -von den Evangelisten Lukas und Matthäus -erzählt. In der Bibel ist sie im Neuen Testament unter Lukas 2, 1-20 und Matthäus 2,1 -2,23 zu finden.</p>
        <h4>Warum gibt es zwei Weihnachtsgeschichten?</h4>
        <p>Die Bibel ist von unterschiedlichen Menschen verfasst worden, die zu unterschiedlichen Zeiten gelebt haben. Zu ihnen gehören auch Lukas und Matthäus -zwei der vier Verfasser der vier Evangelien. Daher existieren auch unterschiedliche Versionen der Weihnachtsgeschichte.</p>
        <h4>Wie alt ist die Weihnachtsgeschichte?</h4>
        <p>Das Evangelium nach Lukas ist wahrscheinlich in den Jahren 80-90 n. Chr. entstanden. Das Evangelium nach Matthäus in den Jahren 60-100 n. Chr. Demnach ist die Weihnachts-geschichte über 1.900 Jahre alt.</p>
      </div>
    </div>
  );
}

export default Stories1;
