import Tile from "../../Tile";
import "./Home.css";

function Home() {
  return (
    <div className="tile-container">
      <Tile label="Fakten - Teil 1" to="/facts/1" />
      <Tile label="Die Weihnachtsgeschichte" to="/stories/1" />
      <Tile label="Das berühmteste Weihnachtslied" to="/songs/1" />
      <Tile label="Weihnachtslieder-Fakten - Teil 1" to="/facts/songs/1" />
      <Tile label="Weihnachtslieder-Fakten - Teil 2" to="/facts/songs/2" />
      <Tile label="Eine Elfengeschichte" to="/stories/2" />
      <Tile label="Fakten - Teil 2" to="/facts/2" />
      <Tile label="Fakten - Teil 3" to="/facts/3" />
      <Tile label="Fakten - Teil 4" to="/facts/4" />
      <Tile label="Fakten - Teil 5" to="/facts/5" />

    </div>
  );
}

export default Home;
