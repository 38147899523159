function Songs1() {
  return (
    <div>
      <h1>Das berühmteste Weihnachtslied der Welt</h1>
      <div className="one-column">
        <h4>Die Geschichte des Liedes "Stille Nacht"</h4>
        <p>Dieses Jahr ist es 204 Jahre her, dass das berühmteste Weihnachtslied der Welt uraufgeführt wurde. Im Mittelpunkt des Weihnachtsmarktes in Oberndorf im Salzburger Land steht -auf einem kleinen Hügel -die Stille-Nacht-Gedächtniskapelle, die einen Blick über die Salzach hinweg auf die Schwesterstadt Laufen erlaubt, die zu Bayern gehört. 1937 wurde die Kapelle errichtet. Sie steht an jener Stelle, wo sich einst die St. Nikola-Kirche befand, die wegen Baufälligkeit abgetragen werden musste. Hier wurde in der Christmette am 24. Dezember 1818 das Weihnachtslied "Stille Nacht! Heilige Nacht!" erstmals gesungen: Jenes schlichte Lied, das Weltruhm erlangen sollte und heute in mehr als 300 Sprachen und Dialekten gesungen wird.</p>
        <h4>Lehrer Franz Xaver Gruber komponierte «Stille Nacht» an einem Tag</h4>
        <p>Lange Schlangen bilden sich im Advent und besonders am Heiligen Abend vor der kleinen Kapelle, die an die Schöpfer von "Stille Nacht! Heilige Nacht!" erinnert: an den Lehrer Franz Xaver Gruber und den Hilfspriester Joseph Mohr. Am 24. Dezember 1818 hatte Joseph Mohr ein Problem. Er wollte unbedingt Musik in der Christmette haben. Die frohe Botschaft ohne Lieder? Die Orgel in der St. Nikola-Kirche war kaputt. Ob es am Hochwasser lag oder an hungrigen Mäusen -das weiß heute niemand mehr. In seiner Not wandte er sich am Morgen des 24. an Franz Xaver Gruber. Und der machte sich sofort ans Komponieren. Pünktlich zur Christmette war das Werk fix und fertig.</p>
      </div>
    </div>
  );
}

export default Songs1;
