function Stories2() {
  return (
    <div>
      <h1>Unfall am Nordpol</h1>
      <div className="one-column">
        <p>Am Nordpol geht es zurzeit hektisch zu und her. Die Weihnachtselfen sind in vollen Zügen der letzten Weihnachtsvorbereitungen und arbeiten beinahe Tag und Nacht an der Geschenkeproduktion.<br />
          Die strenge Zeit des Weihnachtsmannes kommt erst noch. Er nimmt es zurzeit noch gemütlich und isst genüsslich die Plätzchen, welche seine Frau mit viel Liebe gebacken hat. Meistens muss er dies heimlich tun, da seine Frau ihn sonst tadelt.<br />
          Leider ist sein Bauch durch das ständige Naschen, um einiges gewachsen und wächst täglich weiter.<br />
          Während der Weihnachtszeit gehört es zu Santas Aufgaben, die Elfen regelmässig in der Produktionswerkstatt zu besuchen.<br />
          Als er durch die Fabrik läuft, um die Elfen zu grüssen, streift sein Bauch den Hebel der Produktionsmaschine, welcher daraufhin abbricht.<br />
          Leider gibt es zurzeit Lieferschwierigkeiten und der Ersatzhebel kann nicht geliefert werden. Deshalb beschliessen die Elfen, die Produktionsstätte zu verlegen, damit sie bis Weihnachten fertig werden und keine Kinder ohne Geschenke Weihnachten feiern müssen. Auf der Suche nach einer neuen Produktionswerkstatt gelangen die Elfen nach Ettingen und stellen fest, dass es bei Aegerters dieses Jahr keine Weihnachtsbeleuchtung gibt und somit genügend Platz im Vorgarten besteht. Daher beschliessen sie, die Produktion hierhin zu verlegen und weiterzuführen.<br />
          Nach einem langen Ritt auf den Rentieren plagt die Elfen, wegen der Zeitverschiebung zum Nordpol, auch noch der Jetlag, und sie sind sehr müde. Da es am Nordpol in dieser Jahreszeit immer dunkel ist, schlafen die Elfen tagsüber und man sieht sie kaum bei der Arbeit. Sobald es jedoch eindunkelt, erwachen die kleinen Elfen zum Leben und arbeiten wie fleissige Bienchen, damit die Geschenke fertig werden und sämtliche Kinderaugen am Weihnachtsabend leuchten.
        </p>
      </div>
    </div>
  );
}

export default Stories2;
