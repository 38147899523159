import bottom from "./res/bottom.png";
import "./Footer.css";

function Footer() {
  return (
    <footer style={{ height: "35.6vw" }}>
      <img className="bottom-img" src={bottom} alt="" />
    </footer>
  );
}

export default Footer;
