function Facts2() {
  return (
    <div>
      <h1>Frohe Weihnachten</h1>
      <div className="two-columns">
        <div>
          <h4>Geburtstag unbekannt</h4>
          <p>Die ältesten christlichen Kalender berichten von der Geburt Jesu im Frühling. Erst im 4. Jahrhundert setzen die Römer den 25. Dezember als Termin fest, in Übereinstimmung mit dem Fest des antiken römischen Sonnengottes. Im Neuen Testament steht nichts überden Geburtstag von Jesus. Für die ersten Christen spielte der auch keine Rolle, da Todestage für sie viel mehr Bedeutung hatten.</p>
        </div>
        <div>
          <h4>Wie viele Nadeln hat der Christbaum?</h4>
          <p>Die Nordmann-Tanne als beliebtester Christbaum stammt ursprünglich aus dem Kaukasus. Die «Sendung mit der Maus» hat in einem Versuch alle Nadeln eines durchschnittlichen Baumes gezählt und ist auf 178'333 gekommen. Viel Spass beim Zählen der Nadeln Ihres Christbaumes.</p>
        </div>
      </div>
    </div>
  );
}

export default Facts2;
