function Facts5() {
  return (
    <div>
      <h1>Frohe Weihnachten</h1>
      <div className="two-columns">
        <div>
          <h4>Woher kommt der Weihnachtsmann?</h4>
          <p>Wussten Sie,dass die Figur des heutebekannten Weihnachtsmannes sehr demBischof Nikolaus vonMyra ähnelt, der im 4.Jahrhundert nach Christus grosszügigGeschenke an arme Menschen verteilte. War der Weihnachtsmann also doch nicht die Erfindung von Coca-Cola?Coca-Colahat den Weihnachtsmann in aller Welt bekannt gemacht.1931 beauftragte die Coca-Cola Company den Zeichner Haddon Sundblom, „Santa Claus“ für einen weihnachtlichen Werbefeldzug zu zeichnen. Er designte einen grossväterlichen Typ mit Rauschebart, rotem Mantel undweissem Pelzkragen.</p>
        </div>
        <div>
          <h4>Der längste Lebkuchen der Welt</h4>
          <p>Wussten Sie, dass der längste Lebkuchen der Welt 1.052,3 m lang war. Die beiden Köche Stefan Koch und Konrad Friedmann haben diesen Lebkuchen im Rahmen einer Wohltätigkeitsaktion gebacken. Anschliessend wurde der Riesen-Lebkuchen stückchenweise gespendet. Eine tolle Aktion!</p>
        </div>
      </div>
    </div>
  );
}

export default Facts5;
