function SongFacts1() {
  return (
    <div>
      <h1>Fakten zu Weihnachtsliedern - Teil 1</h1>
      <div className="one-column">
        <h4>Das Meistgespielte: "Last Christmas" - Wham!</h4>
        <p>Alleine in Grossbritannien ging der Song im ersten Jahr über 1,6 Millionen Mal über den Ladentisch, jedoch konnte er sich nie die Spitzenposition der Charts sichern.</p>
        <h4>Das Bekannteste: "Stille Nacht, heilige Nacht" - Franz Xaver Gruber, Joseph Mohr</h4>
        <p>Als weltweit bekanntestes Weihnachtslied aller Zeiten gilt „Stille Nacht, heilige Nacht“. Uraufgeführt wurde das Stück zu Heiligabend 1818 vom Arnsdorfer Organisten Franz Xaver Gruber und dem Pfarrer Joseph Mohr in Oberndorf bei Salzburg.</p>
        <h4>Das Meistverkaufte: "White Christmas" - Irving Berlin, Bing Crosby</h4>
        <p>Laut Guinness-Buch der Rekorde wurden die von Bing Crosby gesungenen Versionen des Klassikers 50 Millionen Mal verkauft. Damit ist der Song die weltweit meistvekaufte Single. Zusammen mit den Versionen anderer Künstler kommen die Verkäufe auf knapp 150 Millionen Zähler –Tendenz steigend, da die Single seit 1942 jede Adventszeit neu erscheint.</p>
      </div>
    </div>
  );
}

export default SongFacts1;
