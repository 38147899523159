function Facts3() {
  return (
    <div>
      <h1>Frohe Weihnachten</h1>
      <div className="two-columns">
        <div>
          <h4>Beziehungsstress</h4>
          <p>Eine Untersuchung aus dem Jahr 2010 hat gezeigt, dass in den zwei Wochen vor Weihnachten besonders viele Facebook-User ihren Beziehungs-status auf «Single» ändern. Da lohnt es sich, bis zum Fest durchzuhalten, denn der 24. Dezember ist der Tag mit den wenigsten Trennungen im Jahr. </p>
        </div>
        <div>
          <h4>Gesundheitsrisiko</h4>
          <p>Der ganze Weihnachtsstress geht an die Gesundheit. Laut einer Untersuchung einer deutschen Krankenkasse gibt es anden Weihnachtstagen rund ein Drittel mehr Herzinfarkte als im Jahresschnitt.</p>
        </div>
      </div>
      <h4>Plastikbäume</h4>
      <p>Damit ein Weihnachtsbaum aus Plastik weniger schädlich für die Umwelt ist, als die Verwendung einer echten Tanne, müsste er über 20 Jahre lang verwendet werden.</p>
    </div>
  );
}

export default Facts3;
